import store from "@/store";
import { getCurrentStep, updateProgress } from "@/services/guide.service";
import axiosInstance from "@/services/axios.service";
import i18n from "@/i18n";
import { useModal } from "vue-final-modal";
import GuideStepRedirect from "@/components/UI/modals/GuideStepRedirect.vue";

export async function defaultRedirectMiddleware(to, from, next) {
  // Игнорируем редирект для неавторизованных роутов
  const publicRoutes = ['login', 'register', 'forgot-password', 'main'];
  if (publicRoutes.includes(to.name)) {
    return next();
  }

  // Игнорируем редирект для системных и служебных роутов
  const ignoredRoutes = [
    'profile',
    'error',
    'error-404',
    'error-403',
    'support',
    'faq:default',
    'faq:article',
    'points',
  ];

  // Игнорируем дочерние роуты основных разделов
  const childRoutes = ['bonus', 'discount', 'edit-product', 'new-product', 'new-point', 'edit-point'];
  // console.log("to.name", to.name);

  if (ignoredRoutes.includes(to.name) || childRoutes.includes(to.name)) {
    return next();
  }

  // console.log("defaultRedirectMiddleware");

  // Получаем прогресс с сервера или из store
  let userProgress = store.getters.get_progress;

  if (userProgress === null || userProgress === undefined) {
    const urlRead = `/qbeek/merchant/config`;
    const currentProgress = await axiosInstance.get(urlRead);

    if (!currentProgress) {
      console.log("currentProgress:false", typeof currentProgress);
      await updateProgress();
    } else {
      // console.log("currentProgress:true", typeof currentProgress);
      // console.log("currentProgress:true", JSON.stringify(currentProgress));
    }

    userProgress = currentProgress?.progress || 0;
    store.dispatch("SET_PROGRESS", userProgress);
  }

  // Получаем текущий шаг настройки
  const currentStep = await getCurrentStep();


  if ((userProgress < 5) && (currentStep?.step && (to.name !== currentStep?.step))) {

    const modal = useModal({
      component: GuideStepRedirect,
      slots: {
        header: currentStep.header,
        desc: currentStep.desc
      }
    });

    modal.open();

    return next({
      name: currentStep.step,
      params: { locale: i18n.global.locale }
    });
  }

  return next();
}
