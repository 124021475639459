import router from "@/router";
// import { getAccessToken, getQbeekAuth } from "@/services/auth.service";
// import {
//   createAuthService,
//   resetAuthService,
// } from "@/modules/auth/auth.service";
// createAuthService();
import { useAuth } from '@/composables/useAuth'
const {
  login: userLogin,
  logout,
  switchMerchant,
  isAuthenticated,
  merchants,
  merchantId,
  user,
  error
} = useAuth()
import {
  sendPasswordResetEmail,
  signOut,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getUserState, auth } from "@/firebase";
import { getMerchantOne } from "@/services/merchant.service";
import { getUserRole, getUser } from "@/services/user.service";
import { useStorage } from "@vueuse/core";
import { resetAuthService } from "@/modules/auth/auth.service";
import store from "@/store";
import axiosInstance from "@/services/axios.service";

export default {
  state: () => ({
    menuitem: "",
    user: null,
    merchant: null,
    errorLogin: null,
    errorPassword: null,
    messageSuccessReset: null,
    userid: null,
    role: null,
    loyalty: null,
    pointIDS: null,
    units: null,
    progress: null,
    first_launch: true,
    isLoading: false,
  }),
  mutations: {
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    set_progress(state, progress) {
      state.progress = progress;
    },
    set_loyalty(state, loyalty) {
      state.loyalty = loyalty;
    },
    set_merchantSlugName(state, merchantSlugName) {
      state.merchant.merchantSlugName = merchantSlugName;
    },
    set_merchantName(state, merchantName) {
      state.merchant.merchantName = merchantName;
    },
    SET_MENUITEM(state, menuitem) {
      state.menuitem = menuitem;
    },
    set_merchant(state, merchant) {
      state.merchant = merchant;
    },
    set_userid(state, userid) {
      state.userid = userid;
    },
    set_role(state, role) {
      state.role = role;
    },
    reset_merchant(state) {
      state.merchant = null;
    },
    reset_userid(state) {
      state.userid = null;
    },
    reset_role(state) {
      state.role = null;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    CLEAR_USER(state) {
      state.user = null;
      state.merchant = null;
    },
    SET_ERROR_LOGIN(state, errorLogin) {
      state.errorLogin = errorLogin;
    },
    SET_ERROR_RESET(state, errorLogin) {
      state.errorLogin = errorLogin;
    },

    SET_ERROR_PASSWORD(state, errorPassword) {
      state.errorPassword = errorPassword;
    },
    SET_MESSAGE_FOR_SUCCESS_RESET(state, messageSuccessReset) {
      state.messageSuccessReset = messageSuccessReset;
    },
    CLEAR_MESSAGE_FOR_SUCCESS_RESET(state) {
      state.messageSuccessReset = null;
    },
    set_currencySign(state, currencySign) {
      state.merchant.currencySign = currencySign;
    },
    set_units(state, units) {
      state.units = units;
    },
    set_first_launch(state, value) {
      state.first_launch = value;
    },
  },
  actions: {
    SET_ISLOADING({ commit }, value) {
      commit("setIsLoading", value);
    },
    SET_PROGRESS({ commit }, progress) {
      commit("set_progress", progress);
    },
    SET_ERROR_LOGIN({ commit }, errorLogin) {
      commit("SET_ERROR_LOGIN", errorLogin);
    },
    setMenuitem({ commit }, details) {
      commit("SET_MENUITEM", details);
    },
    async login({ commit, state }, details) {
      const { email, password, isRememberMe } = details;
      this.dispatch("SET_ISLOADING", true);
      try {
        const auth = await userLogin({ email, password }, isRememberMe);
        console.log("****====>>> auth", auth);

        if (state?.role === "back-office") {
          router.replace({ name: "merchants" });
        } else {
          const urlRead = `/qbeek/merchant/config`;
          const currentProgress = await axiosInstance.get(urlRead);

          store.dispatch("SET_PROGRESS", currentProgress?.progress || 0);
          console.log('login:merchant', "main");
          await router.replace({ name: "main" });
        }

        sessionStorage.setItem("email", email);
        sessionStorage.removeItem("stateItems");
      } catch (error) {
        console.log('login:error:***===>>>', error);
        return;
      } finally {

        this.dispatch("SET_ISLOADING", false);
      }

    },
    async logout({ commit }) {
      await logout();
      commit("CLEAR_USER");
      resetAuthService();
      localStorage.clear();
      router.push({ name: "login" });
    },
    async reset_password({ commit }, details) {
      const { email } = details;
      //       console.log("reset mail", email);
      sendPasswordResetEmail(auth, email)
        .then(() => {
          //console.log("res");
          commit(
            "SET_MESSAGE_FOR_SUCCESS_RESET",
            "login.forgotQuery.messageSuccessReset"
          );
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            commit("SET_ERROR_RESET", "login.errors.resetNotEmail");
          }
        });
    },
    async get_merchant({ commit }) {
      const user = await getUserState();
      const userDb = await getUser([
        "firstName",
        "lastName",
        "email",
        "phones",
        "point",
      ]);
      // console.log("user:get_merchant", user);

      const merchant = await getMerchantOne(/* user.uid */);
      const role = await getUserRole();
      if (user) {
        commit("set_merchant", merchant);
        commit("set_userid", user.uid);
        commit("set_role", role);
        commit("SET_USER", userDb);
      } else {
        commit("reset_merchant");
        commit("reset_userid");
        commit("reset_role");
      }
    },
    get_units({ commit }, units) {
      commit("set_units", units);
    },
    SET_FIRST_LAUNCH({ commit }, value) {
      commit("set_first_launch", value);
    },
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
    get_progress(state) {
      return state.progress;
    },
    get_menuitem(state) {
      return state.menuitem;
    },
    errorPassword(state) {
      return state.errorPassword;
    },
    errorLogin(state) {
      return state.errorLogin;
    },
    messageForgotSuccess(state) {
      return state.messageSuccessReset;
    },
    merchant(state) {
      return state.merchant;
    },
    userid(state) {
      return state.userid;
    },
    user(state) {
      return state.user;
    },
    role(state) {
      return state.role;
    },
    pointIDS(state) {
      return state.user?.point?.map((p) => p.id);
    },
    merchantSlugName(state) {
      return state.merchant?.merchantSlugName;
    },
    merchantId(state) {
      return state.merchant?.merchantId;
    },
    currencySymbol(state) {
      return state.merchant?.currencySign?.currencySign;
    },
    currencySign(state) {
      return state.merchant?.currencySign;
    },
    loyalty(state) {
      return state.loyalty;
    },
    units(state) {
      return state.merchant?.units;
    },
    get_first_launch(state) {
      return state.first_launch;
    },
  },
};
