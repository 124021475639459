<template>
  <text-input ref="inputRef" type="text" v-bind="$attrs" />
  <!-- <input ref="inputRef" type="text" /> -->
</template>

<script setup>
import { watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import { watchDebounced } from "@vueuse/core";
import TextInput from "./TextInput.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: [Number, String],
  precision: {
    type: Number,
    default: 2,
  },
  min: {
    type: Number,
    default: -1000000,
  },
  max: {
    type: Number,
    default: 1000000,
  },
});

const options = {
  currency: "EUR",
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  currencyDisplay: "hidden",
  precision: props.precision,
  valueRange: {
    min: props.min,
    max: props.max,
  },
};

watch(
  () => props.modelValue,
  (value) => {
    setValue(value);
  }
);

// eslint-disable-next-line no-undef
const emit = defineEmits({
  "update:modelValue": null,
});

const { inputRef, numberValue, setValue } = useCurrencyInput(options, false);
watchDebounced(
  numberValue,
  (value) => {
    const isNull = Object.is(value, null);
    const newValue = isNull ? 0 : value;
    if (numberValue.value > props.max) setValue(props.max);
    setValue(value);
    emit("update:modelValue", newValue);
  },
  {
    debounce: 200,
  }
);
</script>
