<template>
  <div class="left-container">
    <!-- top list -->
    <div class="logo">
      <router-link :to="{ name: 'main' }">
        <img v-if="isOpen" class="logo__img" src="@/assets/img/logo-text.svg" alt="logo" width="108" height="32">
        <img v-else src="@/assets/img/logo-qb.svg" alt="logo" width="32" height="32">
      </router-link>
    </div>
    <div class="left-container__wrapper">
      <div class="left-container__top">
        <template v-for="item in listMenu('top')" :key="item?.routeName">
          <list-item :item="item" :route-name="item.routeName" :is-dropdown="item.isDropdown"
            :with-badge="!!item.withBadge" :radios="item.radios || []" :is-open="isOpen" :paths="item.paths" />
        </template>
      </div>
      <!-- {{ listMenu("top") }} -->
      <!-- {{ listMenu("bottom") }} -->

      <!-- bottom list -->
      <div class="left-container__bottom">
        <template v-for="item in listMenu('bottom')" :key="item?.routeName">
          <list-item :item="item" :route-name="item.routeName" :is-dropdown="item.isDropdown"
            :with-badge="item.withBadge" :radios="item.radios || []" :paths="item.paths" />
        </template>
        <version />
      </div>
      <v-icon :name="`small-arrow-tag-${isOpen ? 'on' : 'off'}`" class="icon-tag" width="13" height="25" stroke="none"
        @click="toggleSidebar" />
    </div>
  </div>
</template>

<script setup>
import ListItem from "./ListItem.vue";
import Version from "./Version.vue";
import { AccessPermissionsEnum } from "@/router/constants/";
import { useAuthService } from "@/modules/auth/auth.service";
import { ref, computed, inject, watch, onMounted } from "vue";
const { checkHasPermission } = useAuthService();
const isOpen = ref(true);
const checkForSidebar = inject("checkForSidebar");
watch(checkForSidebar, () => {
  if (checkForSidebar.value) {
    isOpen.value = false;
  }
});
const widthSidebar = computed(() => {
  return isOpen.value ? "270px" : "80px";
});
const tagSidebar = computed(() => {
  return isOpen.value ? "0" : "-13px";
});
const ListSideMenu = ref([
  {
    routeName: "main",
    text: "leftSideMenu.main",
    icon: "home",
    permissions: [AccessPermissionsEnum.CAN_READ_MAIN],
    place: "top",
    paths: [],
  },
  {
    routeName: "merchants",
    text: "leftSideMenu.merchants",
    icon: "three-users",
    permissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
    place: "top",
    paths: ["merchants"],
  },
  {
    routeName: "agents",
    text: "leftSideMenu.agents",
    icon: "agents",
    permissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
    place: "top",
    paths: ["agents"],
  },
  // {
  //   routeName: "wallet",
  //   text: "leftSideMenu.wallet",
  //   icon: "wallet-card",
  //   permissions: [AccessPermissionsEnum.CAN_READ_WALLET],
  //   place: "top",
  //   paths: ["templates/client"],
  // },
  {
    routeName: "wallet",
    text: "leftSideMenu.pselection",
    icon: "medal-star",
    permissions: [
      AccessPermissionsEnum.CAN_READ_PSELECTION,
      AccessPermissionsEnum.CAN_READ_WALLET,
    ],
    place: "top",
    paths: ["/loyalty"],
  },
  {
    routeName: "points",
    text: "leftSideMenu.pointsOfSale",
    icon: "map-pin-line",
    permissions: [AccessPermissionsEnum.CAN_READ_CATALOG_POINTS],
    place: "top",
    paths: ["points"],
  },
  {
    routeName: "catalog:settings",
    text: "leftSideMenu.catalog",
    icon: "qr-code",
    permissions: [AccessPermissionsEnum.CAN_READ_CATALOG_POINTS],
    place: "top",
    paths: ["catalog"],
  },

  {
    text: "leftSideMenu.staff",
    routeName: "staff:list",
    icon: "three-users",
    permissions: [AccessPermissionsEnum.CAN_READ_STAFF],
    place: "top",
    paths: ["staff"],
  },
  {
    routeName: "crm",
    text: "leftSideMenu.crm",
    icon: "crm",
    permissions: [AccessPermissionsEnum.CAN_READ_CRM],
    place: "top",
    paths: ["crm"],
  },
  {
    routeName: "broadcast",
    text: "leftSideMenu.broadcast",
    icon: "broadcast",
    permissions: [AccessPermissionsEnum.CAN_READ_CRM], // TODO: change to broadcast
    place: "top",
    paths: ["/broadcast",],
  },
  // {
  //   routeName: "statistic",
  //   text: "leftSideMenu.statistic",
  //   icon: "statistic",
  //   permissions: [AccessPermissionsEnum.CAN_READ_STATISTIC],
  //   place: "top",
  //   paths: ["/statistic"],
  // },
  {
    routeName: "integrations",
    text: "leftSideMenu.integrations",
    icon: "puzzle",
    permissions: [AccessPermissionsEnum.CAN_READ_INTEGRATIONS],
    place: "top",
    paths: ["/integrations"],
  },
  {
    routeName: "faq",
    text: "leftSideMenu.faq",
    icon: "info",
    permissions: [AccessPermissionsEnum.CAN_READ_FAQ],
    place: "bottom",
    paths: ["faq"],
  },
  {
    routeName: "support",
    text: "leftSideMenu.support",
    icon: "gear",
    permissions: [AccessPermissionsEnum.CAN_READ_SUPPORT],
    place: "bottom",
    paths: ["support"],
  },
]);
const ListSideMenuFiltered = computed(() =>
  ListSideMenu.value?.filter(({ permissions = false }) =>
    checkHasPermission(permissions)
  )
);
const listMenu = (lsp) =>
  ListSideMenuFiltered?.value?.filter((item) => item.place === lsp);
function toggleSidebar() {
  isOpen.value = !isOpen.value;
}
onMounted(() => {
  if (checkForSidebar.value) isOpen.value = false;
});
</script>

<style lang="scss">
.left-container {
  background-color: #f5f6f8;
  /* padding: 0px 0px 32px 0px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;

  /* top: 0; */
  /* bottom: 0; */
  /* flex: 260px; */
  &__wrapper {
    position: relative;
    width: v-bind(widthSidebar);
    min-width: v-bind(widthSidebar);
    max-width: v-bind(widthSidebar);
    margin: 16px;
    margin-right: 0px;
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
    transition: 0.4s ease-in-out;
  }

  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;

    &>*+* {
      margin-top: 4px;
    }

    width: 100%;
  }

  transition: 0.4s;
}

.icon-tag {
  position: absolute;
  right: v-bind(tagSidebar);
  top: 50%;
  transform: translateY(-50%);
  /* transform: translateX(100%); */
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 16px; */
  height: 64px;
  min-height: 64px;
  width: 100%;
  background-color: #fff;
  padding-left: 16px;
}
</style>
