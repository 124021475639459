export default {
  namespaced: false,
  state: {
    basket: [],
    pointId: null,
    searchResult: [],
    activePoint: null,
  },
  getters: {
    searchItems:
      (state) =>
        (searchTerm, fields = ["name", "category", "sku"]) => {
          if (searchTerm.trim() === "") {
            return state.basket;
          } else {
            console.log(
              "searchItems: ",
              searchTerm,
              "fields: ",
              fields,
              "state.basket: ",
              state.basket
            );

            return state.basket?.filter((item) =>
              fields.some(
                (field) =>
                  item[field] &&
                  item[field]
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
              )
            );
          }
        },
    itemCount: (state) => state.basket.length,
    orderSum: (state) =>
      state.basket.reduce(
        (total, item) => total + Number(item.price) * Number(item.count),
        0
      ),
    allItems: (state) => state.basket,
    pointId: (state) => state.pointId,
    getSearchResult: (state) => state.searchResult,
    activePoint: (state) => state.activePoint,
  },
  mutations: {
    clearItems(state) {
      state.basket = [];
    },
    setPointId(state, id) {
      state.pointId = id;
    },
    resetPointId(state) {
      state.pointId = null;
    },
    addItem(state, item) {
      if (Array.isArray(item)) {
        state.basket = item;
      } else {
        const index = state.basket.findIndex(
          (existingItem) => existingItem.id === item.id
        );
        if (index !== -1) {
          if (state.basket[index].count !== item.count) {
            if (item.count === 0) {
              state.basket.splice(index, 1);
            } else {
              state.basket.splice(index, 1, item);
            }
          }
        } else {
          state.basket.push(item);
        }
      }
    },
    removeItem(state, id) {
      const index = state.basket.findIndex((i) => i.id === id);
      if (index !== -1) {
        state.basket.splice(index, 1);
      }
    },
    increaseItemCount(state, id) {
      const item = state.basket.find((i) => i.id === id);
      if (item && item.count < 999) {
        item.count++;
      }
    },
    decreaseItemCount(state, id) {
      const item = state.basket.find((i) => i.id === id);
      if (item && item.count > 0) {
        item.count--;
      }
    },
    setSearchResult(state, result) {
      state.searchResult = result;
    },
    setPoint(state, point) {
      state.activePoint = point;
    },
  },
  actions: {
    searchItems: ({ commit, state }, searchTerm) => {
      if (!searchTerm) {
        commit("setSearchResult", state.basket);
        return;
      }
      const result = state.basket?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      commit("setSearchResult", result);
    },
    addItem({ commit }, item) {
      commit("addItem", item);
    },
    removeItem({ commit }, item) {
      commit("removeItem", item);
    },
    setActivePoint({ commit }, point) {
      commit("setPoint", point);
    },
  },
};
